import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export const getAdvancedOrderDetails = async (payload: any) => {
  try {
    return await fetchApiHub(
      '/action/orderSearch/advancedOrderSearch',
      {
        method: 'POST',
      },
      payload,
    )
  } catch (err) {
    console.log('Error', err)
  }
}

export const getOrderDetails = async (payload: any) => {
  try {
    return await fetchApiHub(
      '/action/orderSearch/orderDetail',
      {
        method: 'POST',
      },
      payload,
    ).then((data) => {
      if (data.items.length > 0) {
        data['PickUpAtStore'] = data.items.filter((obj) => obj.deliveryType == 'PickUpAtStore')
        data['ShipToAddress'] = data.items.filter(
          (obj) => obj.deliveryType == 'ShipToAddress' || obj.deliveryType == 'ShipToStore',
        )
      }
      return data
    })
  } catch (err) {
    console.log('Error', err)
  }
}
