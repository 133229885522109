import { useRouter } from 'next/router'
import { Button, HStack } from '@chakra-ui/react'
import { useComposable } from 'composable/components/composable-provider'
import { useFormat } from 'helpers/hooks/useFormat'
import { AiOutlineHeart } from 'react-icons/ai'

export const WishlistButton = ({ isLoggedIn }) => {
  const { formatMessage } = useFormat()
  const { accountDrawer } = useComposable()
  const router = useRouter()

  const handleOpenWishlist = () => {
    if (isLoggedIn) {
      window.location.href = '/myaccount/wishlist'
    } else {
      accountDrawer.onOpen()
    }
  }
  return (
    <Button
      title={formatMessage({ id: 'header.account.wishlist' })}
      variant="ghost"
      textDecoration="none"
      size="md"
      name={formatMessage({ id: 'header.account.wishlist' })}
      aria-label={formatMessage({ id: 'header.account.wishlist' })}
      width={'40px'}
      minW={'40px'}
      onClick={() => handleOpenWishlist()}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <HStack gap={2}>
        <AiOutlineHeart size="26px" color="#111111" />
      </HStack>
    </Button>
  )
}
