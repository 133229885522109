import { Button } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoStorefrontOutline } from 'react-icons/io5'
import { DXL_SEARCH_STORES_URL } from './constants'

export const FindStoreButton = () => {
  const { formatMessage } = useFormat({ name: 'common' })

  const openDxlStore = () => {
    window.open(DXL_SEARCH_STORES_URL, '_blank')
  }

  return (
    <Button
      title={formatMessage({ id: 'header.stores.title' })}
      variant="ghost"
      p={2}
      textDecoration="none"
      name="order-status"
      size="md"
      aria-label={formatMessage({ id: 'header.stores.title' })}
      minW={'40px'}
      leftIcon={<IoStorefrontOutline size="26px" color="#111111" />}
      onClick={openDxlStore}
    >
      {formatMessage({ id: 'header.stores.title' })}
    </Button>
  )
}
