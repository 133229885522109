import { Button, HStack } from '@chakra-ui/react'
import { IoPersonCircleOutline } from 'react-icons/io5'
import { useAtgUser } from 'frontastic/contexts'
import { UserAvatar } from '../../../composable/components/user-avatar'

export const GuestButton = ({ verticalButtonProps, onOpenAccountDrawer, isLoggedIn, userFName }) => {
  const { userSessionData } = useAtgUser()
  return (
    <Button
      name="login"
      title="My Account"
      variant="ghost"
      aria-label={`Hi ${isLoggedIn ? `${userFName}` : 'Guest'}`}
      onClick={() => onOpenAccountDrawer?.()}
      {...verticalButtonProps}
      tabIndex={0}
      pr={0}
    >
      <HStack gap={1}>
        {isLoggedIn && !!userSessionData?.firstName && !!userSessionData?.lastName ? (
          <UserAvatar name={`${userSessionData?.firstName} ${userSessionData?.lastName}`} />
        ) : (
          <IoPersonCircleOutline size="26px" color="#111111" />
        )}
      </HStack>
    </Button>
  )
}
