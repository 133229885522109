import {
  Box,
  ChakraProvider,
  Divider,
  Icon,
  Link,
  LinkBox,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  extendTheme,
  useDisclosure,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import OrderDetailsDrawer from './order-details-drawer'
import { SearchIcon } from '@chakra-ui/icons'

const SearchedOrders = (props: any) => {
  const results = props.results
  const [selectedOrder, setSelectedOrder] = useState<any>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleOrderClick = (id: string) => {
    setSelectedOrder(id)
    onOpen()
  }

  const searchOrderLayout = {
    alignItems: ' center',
    borderRadius: 'var(--radii-lg, 8px)',
    background: 'var(--semantic-surface-primary,#FFF)',
    gap: 'var(--sizes-6, 24px)',
  }
  const tableHead = {
    borderBottom: '1px solid var(--semantic-surface-border, #E7EAED)',
    background: 'var(--semantic-surface-muted, #F3F4F6)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    tableTitle: {
      borderBottom: '1px solid var(--semantic-surface-border, #E7EAED)',
      background: 'var(--semantic-surface-muted, #F3F4F6)',
      minHeight: '60px',
      padding: 'var(--sizes-3, 12px)',
      alignItems: 'center',
      gap: 'var(--sizes-2, 8px)',
      alignSelf: 'stretch',
    },
  }
  const tableRow = {
    height: '48px',
    minHeight: '48px',
    padding: 'var(--sizes-1, 6px) var(--sizes-3, 12px)',
    alignItems: 'center',
    gap: 'var(--sizes-2, 8px)',
    alignSelf: 'stretch',
    fontSize: '14px',
  }
  const getDateformat = (timestamp: any) => {
    const date = new Date(timestamp)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })?.format(date)
    return formattedDate || '-'
  }
  const getPhoneformat = (phoneNumber: any) => {
    if (phoneNumber) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    }
  }
  const LinkText = {
    color: 'var(--semantic-text-link, #2062A9)',
    textDecoration: 'underline',
  }
  const statusBar = {
    display: 'flex',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    gap: 'var(--sizes-2-5, 10px)',
    fontWeight: '700',
  }
  const statusInprogress = {
    background: ' var(--semantic-ui-success-light, #D9FADB)',
    color: 'var(--semantic-ui-danger-light, #38A169)',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--radii-base, 4px)',
  }
  const statuscancel = {
    background: ' var(--semantic-ui-danger-light, #FDE5D8)',
    color: 'var(--semantic-ui-success-med, #C42D3A)',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--radii-base, 4px)',
  }
  const statuShipped = {
    background: ' var(--semantic-ui-info-light, #D6F1FC)',
    color: 'var(--semantic-ui-info-dark, #184B94)',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--radii-base, 4px)',
  }
  const statureturn = {
    color: 'var(--core-shading-shading-700, #4E5154)',
    background: ' var(--core-shading-shading-300, #DCDFE4)',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--radii-base, 4px)',
  }
  const noResultfound = {
    display: 'flex',
    padding: 'var(--sizes-12, 48px) var(--sizes-6, 24px)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    flex: '1 0 0',
    alignSelf: 'stretch',
  }
  const noResultHeaderFont = {
    fontSize: '24px',
    fontWeight: '700',
    color: 'var(--semantic-text-primary, #15191C)',
  }

  const customTheme = extendTheme({
    components: {
      Table: {
        variants: {
          orderdetails: {
            tr: {
              _odd: {
                background: '#F3F4F6',
              },
            },
          },
        },
      },
    },
  })

  return (
    <>
      {results?.length > 0 ? (
        <div>
          <Box display={'flex'} flexDirection={'column'}>
            <div style={{ padding: '2px 0', color: 'var(--semantic-text-primary, #15191C)', fontSize: '14px' }}>
              Results Found
            </div>
            <div style={{ color: 'var(--semantic-text-muted, #76797E)', fontSize: '16px' }}>{results?.length} item</div>
          </Box>
          <Box paddingTop={'var(--chakra-space-6, 24px)'}>
            <ChakraProvider theme={customTheme}>
              <TableContainer style={searchOrderLayout}>
                <Table variant="orderdetails" style={searchOrderLayout}>
                  <Thead style={tableHead}>
                    <Tr>
                      <Th style={tableHead.tableTitle}>Order Number</Th>
                      <Th style={tableHead.tableTitle}>Last Name</Th>
                      <Th style={tableHead.tableTitle}>First Name</Th>
                      <Th style={tableHead.tableTitle}>Email Address</Th>
                      <Th style={tableHead.tableTitle}>Phone Number</Th>
                      <Th style={tableHead.tableTitle} textAlign={'right'}>
                        Order Total
                      </Th>
                      <Th style={tableHead.tableTitle} textAlign={'left'}>
                        Order Status
                      </Th>
                      <Th style={tableHead.tableTitle}>Return Status</Th>
                      <Th style={tableHead.tableTitle}>Order Type</Th>
                      <Th style={tableHead.tableTitle}>Order Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {results.map((item, index) => (
                      //  <Link href={`'/order-details?orderId='${item.OrderId}`}>

                      <Tr
                        id={`order_trid_${index}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOrderClick(item.OrderId)}
                        key={index}
                      >
                        <Td style={tableRow}>
                          <Link style={LinkText} onClick={() => handleOrderClick(item.OrderId)}>
                            {item.OrderId}
                          </Link>
                        </Td>
                        <Td style={tableRow}>{item.CustomerLastName}</Td>
                        <Td style={tableRow}> {item.CustomerFirstName}</Td>
                        <Td style={tableRow}>{item.CustomerEmail}</Td>
                        <Td style={tableRow}>{getPhoneformat(item.CustomerPhone)}</Td>
                        <Td style={tableRow} textAlign={'right'}>
                          ${item.OrderTotal}
                        </Td>
                        <Td style={tableRow} textAlign={'left'}>
                          <Box style={statusBar} textTransform={'uppercase'} textAlign={'center'}>
                            {item.FulfillmentStatus == 'In Process' ||
                            item.FulfillmentStatus == 'Allocated' ||
                            item.FulfillmentStatus == 'Partially Allocated' ||
                            item.FulfillmentStatus == 'Picked' ||
                            item.FulfillmentStatus == 'Packed' ||
                            item.FulfillmentStatus == 'Released' ||
                            item.FulfillmentStatus == 'Partially Released' ||
                            item.FulfillmentStatus == 'Back Ordered' ? (
                              <span style={statusInprogress}>IN PROGRESS</span>
                            ) : item.FulfillmentStatus == 'Canceled' ? (
                              <span style={statuscancel}>CANCELED</span>
                            ) : item.FulfillmentStatus == 'Fulfilled' || item.FulfillmentStatus == 'Shipped' ? (
                              <span style={statuShipped}>SHIPPED</span>
                            ) : item.FulfillmentStatus == 'Return' ||
                              item.FulfillmentStatus == 'Received' ||
                              item.FulfillmentStatus == 'Pending Return' ||
                              item.FulfillmentStatus == 'Pending Approval' ||
                              item.FulfillmentStatus == 'Returned' ||
                              item.FulfillmentStatus == 'Partially Returned' ||
                              item.FulfillmentStatus == 'Partially Pending Return' ||
                              item.FulfillmentStatus == 'Partially Pending Return' ||
                              item.FulfillmentStatus == 'Partially Returned' ? (
                              <span style={statureturn}>RETURN</span>
                            ) : item.FulfillmentStatus == 'Partially Fulfilled' ? (
                              <span style={statuShipped}>Partially Fulfilled</span>
                            ) : item.FulfillmentStatus == 'Partially In Process' ? (
                              <span style={statuShipped}>Partially Back Ordered</span>
                            ) : item.FulfillmentStatus == 'Partially Picked' ? (
                              <span style={statuShipped}>Partially Picked</span>
                            ) : item.FulfillmentStatus == '' || item.FulfillmentStatus == undefined ? (
                              <span> - </span>
                            ) : (
                              <span style={statusInprogress}>{item.FulfillmentStatus}</span>
                            )}
                          </Box>
                        </Td>
                        <Td textAlign={'center'} style={tableRow}>
                          {item.ReturnStatus || '-'}
                        </Td>
                        <Td style={tableRow}> {item.OrderType.OrderTypeId}</Td>
                        <Td style={tableRow}>{getDateformat(item.CreatedTimestamp)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </ChakraProvider>
          </Box>
        </div>
      ) : (
        <div>
          <Box style={noResultfound} flexDirection="column">
            <p>
              <SearchIcon
                width="var(--sizes-16, 64px)"
                height="var(--sizes-16, 64px)"
                color="var(--Theme-text,#111111)"
              />
            </p>
            <p style={noResultHeaderFont}>No Results Found</p>
            <Box textAlign="center">
              Sorry, we can’t seem to find any results that match your search criteria. <br />
              <br />
              Please change your search details and try again.
            </Box>
          </Box>
        </div>
      )}
      <OrderDetailsDrawer id={selectedOrder} isOpen={isOpen} onClose={onClose} placement="right"></OrderDetailsDrawer>
    </>
  )
}

export default SearchedOrders
