import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  Stack,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import Toast from 'react-hot-toast'
import { getAdvancedOrderDetails } from 'frontastic/actions/advancedOrderSearch'
import { SearchIcon } from '@chakra-ui/icons'

interface FormData {
  OrderId?: string
  CustomerFirstName?: string
  CustomerLastName?: string
  CustomerEmail?: string
  CustomerPhone?: string | null
}

const schema = yup.object().shape({
  OrderId: yup.string(),
  CustomerFirstName: yup.string(),
  CustomerLastName: yup.string(),
  CustomerEmail: yup.string().email('Invalid email'),
  CustomerPhone: yup.string().nullable(),
})

const OrderSearchForm = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const onClearForm = () => {
    reset()
  }

  const onSubmit = async (data: FormData) => {
    props.callBack({
      orders: [],
      showOrders: false,
    })
    const outputObject = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0
        } else if (typeof value === 'string') {
          return value.trim() !== '' && value.toLowerCase() !== 'nan'
        } else if (typeof value === 'number' && !isNaN(value)) {
          return true
        } else {
          return value !== undefined && value !== null && value !== 'NaN'
        }
      }),
    )

    let finalQuery = Object.entries(outputObject)
      .filter(([key, value]) => value !== undefined && value !== null && value !== '')
      .map(([key, value]) => `${key}='${value}'`)
      .join('&')

    if (finalQuery?.length) {
      const payload = {
        Query: `${finalQuery}`,
        Size: 10,
        Sort: [{ attribute: 'CreatedTimestamp', direction: 'desc' }],
        Page: 0,
        OrderHoldQuery: '',
        OrderUpdatedByQuery: '',
      }
      const response = await getAdvancedOrderDetails(payload)
      const orderdetails = response?.data
      props.callBack({
        orders: orderdetails,
        showOrders: true,
      })
    } else {
      Toast.error('Please select/provide few details to search')
    }
  }

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setValue('CustomerPhone', inputValue)
    if (inputValue === '' || /^\d{10}$/.test(inputValue)) {
      schema.validate({ phone: inputValue }).then(() => {
        setError('CustomerPhone', null)
      })
    } else {
      setError('CustomerPhone', {
        type: 'manual',
        message: 'Phone must be a 10-digit number or blank',
      })
    }
  }

  const headerStyle = {
    fontFamily: '__Libre_Franklin_60df2c',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '48px',
  }

  const formLabel = {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '16.8px',
  }
  const formwraper = {
    display: 'flex',
    alignItems: 'flex-end',
  }
  const formControl = {
    marginRight: 0,
    marginTop: 0,
    alignItems: 'flex-start',
    gap: 'var(--chakra-space-2, 8px)',
    flexShrink: '0',
    marginBottom: '0',
  }
  const formWrapperBtn = {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--chakra-space-2, 8px)',
  }

  return (
    <VStack gap={0} align="stretch" mx="auto">
      <h1 style={headerStyle}>Order Search</h1>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'var(--sizes-4, 16px)'}
        padding={'var(--sizes-7, 28px) 0 var(--sizes-5, 20px) 0'}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            style={formwraper}
            gap={{ base: 'var(--chakra-space-4, 16px)', md: 'var(--chakra-space-7, 28px) var(--chakra-space-4, 16px)' }}
            direction={{ base: 'column', md: 'row' }}
            flexWrap="wrap"
          >
            <FormControl
              style={formControl}
              flexDirection={{ base: 'column', md: 'row' }}
              maxWidth={{ base: 'auto', md: '298px' }}
              mb={{ base: 4, md: 0 }}
              mr={{ base: 0, md: 4 }}
              isInvalid={!!errors.OrderId}
            >
              <FormLabel style={formLabel}>Order Number</FormLabel>
              <InputGroup>
                <Input type="number" placeholder="123456789" {...register('OrderId', { valueAsNumber: true })} />
              </InputGroup>

              <FormErrorMessage position="absolute" marginTop="0">
                {errors.OrderId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              style={formControl}
              flexDirection={{ base: 'column', md: 'row' }}
              maxWidth={{ base: 'auto', md: '298px' }}
              mb={{ base: 4, md: 0 }}
              isInvalid={!!errors.CustomerEmail}
              mt={{ base: 0, md: 4 }}
              mr={{ base: 0, md: 4 }}
            >
              <FormLabel style={formLabel}>Email Address</FormLabel>
              <InputGroup>
                <Input type="email" placeholder="example@email.com                  " {...register('CustomerEmail')} />
              </InputGroup>
              <FormErrorMessage position="absolute" marginTop="0">
                {errors.CustomerEmail?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              style={formControl}
              flexDirection={{ base: 'column', md: 'row' }}
              maxWidth={{ base: 'auto', md: '298px' }}
              mb={{ base: 4, md: 0 }}
              mr={{ base: 0, md: 4 }}
              isInvalid={!!errors.CustomerFirstName}
            >
              <FormLabel style={formLabel}>Customer First Name</FormLabel>
              <InputGroup>
                <Input type="text" {...register('CustomerFirstName')} />
              </InputGroup>
              <FormErrorMessage position="absolute" marginTop="0">
                {errors.CustomerFirstName?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              style={formControl}
              flexDirection={{ base: 'column', md: 'row' }}
              maxWidth={{ base: 'auto', md: '298px' }}
              mb={{ base: 4, md: 0 }}
              mr={{ base: 0, md: 4 }}
              isInvalid={!!errors.CustomerLastName}
            >
              <FormLabel style={formLabel}>Customer Last Name</FormLabel>
              <InputGroup>
                <Input type="text" {...register('CustomerLastName')} />
              </InputGroup>
              <FormErrorMessage position="absolute" marginTop="0">
                {errors.CustomerLastName?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              style={formControl}
              flexDirection={{ base: 'column', md: 'row' }}
              maxWidth={{ base: 'auto', md: '298px' }}
              mb={{ base: 4, md: 0 }}
              mr={{ base: 0, md: 4 }}
              isInvalid={!!errors.CustomerPhone}
              mt={{ base: 0, md: 4 }}
            >
              <FormLabel style={formLabel}>Phone Number</FormLabel>
              <InputGroup>
                <Input
                  type="tel"
                  placeholder="(555) 555-5555"
                  {...register('CustomerPhone', { valueAsNumber: true })}
                  onChange={handlePhoneChange}
                />
              </InputGroup>

              <FormErrorMessage position="absolute" marginTop="0">
                {errors.CustomerPhone?.message}
              </FormErrorMessage>
            </FormControl>
            <Stack
              style={formWrapperBtn}
              flexDirection={'row'}
              marginLeft={'auto'}
              width={{ base: '100%', md: '268px' }}
            >
              <Button
                colorScheme="gray"
                variant="outline"
                onClick={onClearForm}
                gap="var(--chakra-space-2, 8px)"
                flex="1 0 0"
              >
                Clear
              </Button>
              <Button
                colorScheme="gray"
                type="submit"
                isLoading={isSubmitting}
                gap="var(--chakra-space-2, 8px)"
                flex="1 0 0"
              >
                Apply
              </Button>
            </Stack>
          </Flex>
        </form>
      </Box>
    </VStack>
  )
}

export default OrderSearchForm
