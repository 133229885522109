import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react'
import OrderDetails from '../order-details'

const OrderDetailsDrawer = (props: any) => {
  const btnRef = React.useRef()
  const drawerWidthDesktop = '550px'
  const drawerWidthMobile = '100%'
  const drawerWidth = useBreakpointValue({ base: drawerWidthMobile, md: drawerWidthDesktop })

  return (
    <Drawer isOpen={props.isOpen} placement={props.placement} onClose={props.onClose} finalFocusRef={btnRef}>
      <DrawerOverlay />
      <DrawerContent width={drawerWidth} maxWidth={drawerWidth}>
        <DrawerHeader
          padding={'var(--sizes-2, 8px)'}
          display="flex"
          gap={'var(--sizes-2, 8px)'}
          fontSize={'16px'}
          borderBottom="1px solid var(--semantic-surface-border, #E7EAED)"
        >
          <Box flex={'1'} textAlign={'center'}>
            Order Details
          </Box>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody p={0}>
          <OrderDetails id={props.id} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default OrderDetailsDrawer
