import { forwardRef } from 'react'
import { Button } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { BsTruck } from 'react-icons/bs'

type OrderStatusButtonProps = {
  handleOpenOrderStatusModal: () => void
}

export const OrderStatusButton = forwardRef<HTMLButtonElement, OrderStatusButtonProps>(
  ({ handleOpenOrderStatusModal }, ref) => {
    const { formatMessage } = useFormat()

    return (
      <Button
        ref={ref}
        title={formatMessage({ id: 'header.orderStatus.title' })}
        variant="ghost"
        textDecoration="none"
        p={2}
        name="order-status"
        size="md"
        aria-label={formatMessage({ id: 'header.orderStatus.label' })}
        minW={'40px'}
        leftIcon={<BsTruck size="26px" color="#111111" />}
        onClick={() => handleOpenOrderStatusModal()}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {formatMessage({ id: 'header.orderStatus.label' })}
      </Button>
    )
  },
)

OrderStatusButton.displayName = 'OrderStatusButton'
