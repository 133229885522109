import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useBreakpointValue,
} from '@chakra-ui/react'
import { getOrderDetails } from 'frontastic/actions/advancedOrderSearch'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import OrderItems from './order-item'
import { availableFonts } from 'composable/chakra/theme/foundations/typography'

const OrderDetails = (props: any) => {
  const [orderDetails, setOrderDetails] = useState<any>()
  const [billingAddress, setBillingAddress] = useState<any>()
  const router = useRouter()
  const { orderId } = router.query
  const isMobile = useBreakpointValue({ base: true, md: false })
  useEffect(() => {
    if (orderId) {
      getDetails({ orderId: orderId })
    }
    if (!!props.id) {
      getDetails({ orderId: props.id })
    }
  }, [])

  const getDetails = async (payload: any) => {
    const response = await getOrderDetails(payload)
    setOrderDetails(response)
    setBillingAddress(response?.billingAddress)
    getQuantity()
  }

  const getQuantity = () => {
    let qty = 0
    orderDetails?.items.forEach((obj) => {
      qty += obj.quantity
    })

    return (
      <Box display={'flex'} flexDirection={'row'} fontSize="14px" fontStyle="normal" fontWeight={400}>
        <Text width={'124px'} color={'var(--semantic-text-muted, #76797E)'}>
          Total # of items
        </Text>
        <Text color={'var(--semantic-text-primary, #15191C)'} lineHeight="21px">
          {' '}
          {qty}{' '}
        </Text>
      </Box>
    )
  }

  const getPaymentDetails = (data: any) => {
    const paymentType = data?.paymentType ?? ''
    if (paymentType == 'Credit Card') {
      return (
        <>
          <Text fontSize={'sm'}> {data?.displayName?.replace('ending in', ': XXXX-')} </Text>
          <Text fontSize={'sm'}>
            {' '}
            Expiry: {data?.cardExpiryMonth}/{data?.cardExpiryYear?.slice(-2)}{' '}
          </Text>{' '}
        </>
      )
    } else if (paymentType == 'Gift Card') {
      return (
        <>
          <Text fontWeight={500} fontSize={'sm'}>
            {' '}
            {data?.paymentType}{' '}
          </Text>
          <Text fontSize={'sm'}> {data?.displayName} </Text>
        </>
      )
    } else {
      return (
        <>
          <Text fontWeight={500} fontSize={'sm'}>
            {' '}
            {data?.paymentType}{' '}
          </Text>
        </>
      )
    }
  }

  const OrderPlacedDate = () => {
    const originalDate = orderDetails?.rootData?.CreatedTimestamp
    const dateObj = new Date(originalDate)
    const year = dateObj.getFullYear()

    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    })?.format(dateObj)

    return (
      <>
        <Box display={'flex'} flexDirection={'row'} fontSize="14px" fontStyle="normal" fontWeight={400}>
          <Text width={'124px'} color={'var(--semantic-text-muted, #76797E)'}>
            Order Placed on
          </Text>
          <Text color={'var(--semantic-text-primary, #15191C)'} lineHeight="21px">
            {' '}
            {formattedDate}{' '}
          </Text>
        </Box>
      </>
    )
  }
  const getPhoneformat = (phoneNumber: any) => {
    if (phoneNumber) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    }
  }
  const statusBar = {
    display: 'flex',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    gap: 'var(--sizes-2-5, 10px)',
    fontWeight: '700',
    fontSize: '14px',
  }
  const statusInprogress = {
    background: ' var(--semantic-ui-success-light, #D9FADB)',
    color: 'var(--semantic-ui-danger-light, #38A169)',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--radii-base, 4px)',
  }
  const statuscancel = {
    background: ' var(--semantic-ui-danger-light, #FDE5D8)',
    color: 'var(--semantic-ui-success-med, #C42D3A)',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--radii-base, 4px)',
  }
  const statuShipped = {
    background: ' var(--semantic-ui-info-light, #D6F1FC)',
    color: 'var(--semantic-ui-info-dark, #184B94)',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--radii-base, 4px)',
  }
  const statureturn = {
    color: 'var(--core-shading-shading-700, #4E5154)',
    background: ' var(--core-shading-shading-300, #DCDFE4)',
    padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--radii-base, 4px)',
  }
  const OrderStatusMap = () => {
    const orderStatus = orderDetails?.rootData.FulfillmentStatus
    if (
      orderStatus == 'In Process' ||
      orderStatus == 'Allocated' ||
      orderStatus == 'Partially Allocated' ||
      orderStatus == 'Picked' ||
      orderStatus == 'Packed' ||
      orderStatus == 'Released' ||
      orderStatus == 'Partially Released' ||
      orderStatus == 'Back Ordered'
    ) {
      return <span style={statusInprogress}>IN PROGRESS</span>
    } else if (orderStatus == 'Canceled') {
      return <span style={statuscancel}>CANCELED</span>
    } else if (orderStatus == 'Fulfilled' || orderStatus == 'Shipped') {
      return <span style={statuShipped}>SHIPPED</span>
    } else if (
      orderStatus == 'Return' ||
      orderStatus == 'Received' ||
      orderStatus == 'Pending Return' ||
      orderStatus == 'Pending Approval' ||
      orderStatus == 'Returned' ||
      orderStatus == 'Partially Returned' ||
      orderStatus == 'Partially Pending Return'
    ) {
      return <span style={statureturn}>RETURN</span>
    } else if (orderStatus == 'Partially Fulfilled') {
      return <span style={statuShipped}>Partially Fulfilled</span>
    } else if (orderStatus == 'Partially In Process') {
      return <span style={statuShipped}>Partially Back Ordered</span>
    } else if (orderStatus == 'Partially Picked') {
      return <span style={statuShipped}>Partially Picked</span>
    } else {
      return <span>{orderStatus}</span>
    }
  }

  return (
    <Box
      padding={'var(--sizes-4, 16px) var(--sizes-6, 24px)'}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="var(--sizes-4, 16px)"
    >
      <Box width="100%" display={'flex'} flexDirection={'column'} gap={'var(--sizes-4, 16px)'}>
        <Box style={statusBar} fontFamily={availableFonts.primary}>
          <OrderStatusMap />
        </Box>
        <Heading fontSize="28px" color={'var(--semantic-text-primary, #15191C)'} fontWeight={700}>
          {' '}
          Order #{orderId ?? props.id}{' '}
        </Heading>
        <Box display={'flex'} flexDirection={'column'} gap={'var(--sizes-1, 4px)'}>
          {orderDetails?.rootData?.CreatedTimestamp ? <OrderPlacedDate /> : null}
          {orderDetails ? getQuantity() : null}
        </Box>
      </Box>
      {!!orderDetails ? (
        <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start" gap="var(--sizes-4, 16px)">
          <Grid
            templateColumns={isMobile ? '1fr' : '1fr 1fr 1fr'}
            gap={'12px'}
            padding="var(--sizes-4, 16px)"
            bgColor="var(--shading-shading100, #F4F4F4)"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            alignSelf="stretch"
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1 0 0" alignSelf="stretch">
              <Heading
                mb={1}
                color="var(--semantic-brand-primary, #000)"
                fontWeight={700}
                fontSize="16px"
                lineHeight="19.2px"
              >
                {' '}
                Billed To{' '}
              </Heading>
              <Text fontSize="14px">
                {' '}
                {billingAddress?.fname} {billingAddress?.lname}{' '}
              </Text>
              <Text fontSize="14px"> {billingAddress?.addrLine1} </Text>
              <Text fontSize="14px">
                {' '}
                {billingAddress?.city} {billingAddress?.state} {billingAddress?.zipcode}{' '}
              </Text>
              <Text fontSize="14px"> {getPhoneformat(billingAddress?.phoneNumber)} </Text>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1 0 0" alignSelf="stretch">
              <Heading mb={1} color="var(--semantic-brand-primary, #000)" fontWeight={700} fontSize="16px">
                {' '}
                Payment Method{' '}
              </Heading>
              {orderDetails?.paymentDetails?.map((item) => getPaymentDetails(item))}
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" flex="1 0 0" alignSelf="stretch">
              <Heading mb={1} color="var(--semantic-brand-primary, #000)" fontWeight={700} fontSize="16px">
                {' '}
                Summary of Charges
              </Heading>

              <Flex width="100%">
                <Box flex={1}>
                  <Text textAlign="left" fontSize={'sm'}>
                    {' '}
                    Subtotal{' '}
                  </Text>
                </Box>
                <Box textAlign="right">
                  <Text fontSize={'sm'}> ${orderDetails?.orderSummary?.subTotal?.toFixed(2) ?? '0.00'} </Text>
                </Box>
              </Flex>
              <Flex width="100%">
                <Box flex={1}>
                  <Text textAlign="left" fontSize={'sm'}>
                    {' '}
                    Shipping{' '}
                  </Text>
                </Box>
                <Box>
                  <Text textAlign="right" fontSize={'sm'}>
                    {' '}
                    ${orderDetails?.orderSummary?.Shipping?.toFixed(2) ?? '0.00'}{' '}
                  </Text>
                </Box>
              </Flex>
              <Flex width="100%">
                <Box flex={1}>
                  <Text textAlign="left" fontSize={'sm'}>
                    {' '}
                    Tax{' '}
                  </Text>
                </Box>
                <Box>
                  <Text textAlign="right" fontSize={'sm'}>
                    {' '}
                    ${orderDetails?.orderSummary?.tax?.toFixed(2) ?? '0.00'}{' '}
                  </Text>
                </Box>
              </Flex>
              <Flex width="100%">
                <Box flex={1}>
                  <Text textAlign="left" fontSize={'sm'}>
                    {' '}
                    Handling{' '}
                  </Text>
                </Box>
                <Box>
                  <Text textAlign="right" fontSize={'sm'}>
                    {' '}
                    ${orderDetails?.orderSummary?.Handling?.toFixed(2) ?? '0.00'}{' '}
                  </Text>
                </Box>
              </Flex>
              <Flex width="100%" paddingBottom={'var(--sizes-2, 8px)'}>
                <Box flex={1}>
                  <Text textAlign="left" fontSize={'sm'}>
                    {' '}
                    Promo Applied{' '}
                  </Text>
                </Box>
                <Box>
                  <Text textAlign="right" fontSize={'sm'}>
                    {' '}
                    ${orderDetails?.orderSummary?.discount?.toFixed(2) ?? '0.00'}{' '}
                  </Text>
                </Box>
              </Flex>
              <Stack direction={'row'}>
                <Divider></Divider>
              </Stack>
              <Flex
                width="100%"
                paddingTop="var(--sizes-4, 16px)"
                borderTop={'1px solid var(--semantic-surface-border, #DCDFE4)'}
              >
                <Box flex={1}>
                  <Text fontWeight="700" textAlign="left" fontSize={'16px'}>
                    {' '}
                    Order Total{' '}
                  </Text>
                </Box>

                <Box>
                  <Text fontWeight="700" textAlign="right" fontSize={'16px'}>
                    {' '}
                    ${orderDetails?.orderSummary?.total?.toFixed(2)}{' '}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Grid>
          <Box
            display="flex"
            flexDirection="column"
            alignItems=" flex-start"
            gap="var(--sizes-4, 16px)"
            padding="var(--sizes-6, 24px) 0px"
            align-self="stretch"
            width="100%"
          >
            {orderDetails?.PickUpAtStore?.map((item: any, index) => {
              const deliveryType = item.deliveryType
              return (
                <>
                  {index == 0 ? (
                    <Heading
                      color="var(--semantic-text-primary, #15191C)"
                      fontWeight={700}
                      fontSize="28px"
                      lineHeight="19.2px"
                    >
                      Store Pick
                    </Heading>
                  ) : (
                    ''
                  )}
                  <OrderItems id={`${deliveryType}_${index}`} item={item}></OrderItems>
                </>
              )
            })}
            {orderDetails?.ShipToAddress?.map((item: any, index) => {
              const deliveryType = item.deliveryType
              return (
                <>
                  {index == 0 ? (
                    <Heading mt={2} color="var(--semantic-text-primary, #15191C)" fontWeight={700} fontSize="28px">
                      Ship to Address
                    </Heading>
                  ) : (
                    ''
                  )}
                  <OrderItems id={`${deliveryType}_${index}`} item={item}></OrderItems>
                </>
              )
            })}
          </Box>
          <UnorderedList color={'var(--semantic-text-muted, #76797E)'}>
            <ListItem fontSize={'14px'}>Please allow 3-6 business days for your order to appear as 'shipped.'</ListItem>
            <ListItem fontSize={'14px'}>
              UPS tracking information will be updated within 1-4 days once the order is processed through one of the
              UPS hubs.
            </ListItem>
            <ListItem fontSize={'14px'}>
              Within the continental U.S., you can expect delivery of in-stock orders within 3-7 business days (Mon-Fri,
              excluding holidays) after receiving credit authorization (international orders cannot be tracked online).
            </ListItem>
            <ListItem fontSize={'14px'}>
              Within AK, HI, APO, FPO, and U.S. Territories, allow 20 business days for delivery by the U.S. Postal
              Service. For orders shipped directly by our vendors ('drop ship'), add additional delivery time to
              in-stock orders. Additional delivery times vary by item. Allow 1-2 extra business days for iterations.
            </ListItem>
          </UnorderedList>
        </Box>
      ) : (
        <Center h="100px">
          <Button isLoading loadingText="Loading.." colorScheme="black" variant="link" spinnerPlacement="start">
            {' '}
            Submit{' '}
          </Button>
        </Center>
      )}
    </Box>
  )
}

export default OrderDetails
