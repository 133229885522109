import { Box, Container } from '@chakra-ui/react'
import SearchedOrders from './searched-orders'
import OrderSearchForm from './order-search-form'
import { useState } from 'react'
import BreadCrumb from './breadcrumb'

const AdvancedOrderSearch = () => {
  const [stateData, setStateData] = useState({
    orders: [],
    showOrders: false,
  })
  const callBackFn: any = (data) => {
    setStateData(data)
  }
  return (
    <Container
      maxW="container.2xl"
      paddingInlineStart={{ base: 'var(--sizes-3, 12px)', md: 'var(--sizes-24, 96px)' }}
      paddingInlineEnd={{ base: 'var(--sizes-3, 12px)', md: 'var(--sizes-24, 96px)' }}
    >
      <Box mt={{ base: '24px', md: '64px' }} mb="12px">
        <BreadCrumb />
      </Box>
      <div>
        <OrderSearchForm callBack={callBackFn}></OrderSearchForm>
        {stateData.showOrders ? (
          <SearchedOrders inputData={FormData} results={stateData.orders}>
            {' '}
          </SearchedOrders>
        ) : null}
      </div>
    </Container>
  )
}

export default AdvancedOrderSearch
