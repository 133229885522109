import React from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Text,
  Link,
  Container,
} from '@chakra-ui/react'

type StaticBreadcrumbTasticProps = {
  data: {
    pageTitle: string
  }
}

const StaticBreadcrumb: React.FC<StaticBreadcrumbTasticProps> = ({ data }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const { pageTitle } = data
  return (
    <>
      <ChakraBreadcrumb
        m={10}
        overflowX={isMobile ? 'scroll' : undefined}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        color={'text-muted'}
      >
        <BreadcrumbItem as={Link} href="/" textStyle={'body-75'}>
          <BreadcrumbLink
            as={Text}
            whiteSpace={'nowrap'}
            _hover={{
              textStyle: 'link-75',
            }}
          >
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage textStyle={'body-75'}>
          <BreadcrumbLink as={Text} whiteSpace={'nowrap'}>
            {pageTitle}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </ChakraBreadcrumb>
    </>
  )
}

export default StaticBreadcrumb
