import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  extendTheme,
  ChakraProvider,
  background,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { getOrderDetails } from 'frontastic/actions/advancedOrderSearch'
import { useRouter } from 'next/router'
import { availableFonts } from 'composable/chakra/theme/foundations/typography'

const statusBar = {
  display: 'flex',
  gap: 'var(--sizes-2, 8px)',
  fontWeight: '700',
  fontSize: '14px',
}
const statusInprogress = {
  background: ' var(--semantic-ui-success-light, #D9FADB)',
  color: 'var(--semantic-ui-danger-light, #38A169)',
  padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'var(--radii-base, 4px)',
}
const statuscancel = {
  background: ' var(--semantic-ui-danger-light, #FDE5D8)',
  color: 'var(--semantic-ui-success-med, #C42D3A)',
  padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'var(--radii-base, 4px)',
}
const statuShipped = {
  background: ' var(--semantic-ui-info-light, #D6F1FC)',
  color: 'var(--semantic-ui-info-dark, #184B94)',
  padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'var(--radii-base, 4px)',
}
const statureturn = {
  color: 'var(--core-shading-shading-700, #4E5154)',
  background: ' var(--core-shading-shading-300, #DCDFE4)',
  padding: 'var(--sizes-1, 4px) var(--sizes-1-5, 6px)',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'var(--radii-base, 4px)',
}

const OrderItems = (props: any) => {
  const [orderStatus, setOrderStatus] = useState<any>()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const router = useRouter()
  const { orderId } = router.query
  const item = props.item
  const getDateformat = (timestamp: any) => {
    const date = new Date(timestamp)
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    return formattedDate || '-'
  }

  useEffect(() => {
    if (orderId) {
      getDetails({ orderId: orderId })
    }
  }, [])
  const getDetails = async (payload: any) => {
    const response = await getOrderDetails(payload)
    setOrderStatus(response)
  }
  const OrderStatusMap = () => {
    const orderStatus = item?.status
    if (
      orderStatus == 'In Process' ||
      orderStatus == 'Allocated' ||
      orderStatus == 'Partially Allocated' ||
      orderStatus == 'Picked' ||
      orderStatus == 'Packed' ||
      orderStatus == 'Released' ||
      orderStatus == 'Partially Released' ||
      orderStatus == 'Back Ordered'
    ) {
      return <span style={statusInprogress}>IN PROGRESS</span>
    } else if (orderStatus == 'Canceled') {
      return <span style={statuscancel}>CANCELED</span>
    } else if (orderStatus == 'Fulfilled' || orderStatus == 'Shipped') {
      return <span style={statuShipped}>SHIPPED</span>
    } else if (
      orderStatus == 'Return' ||
      orderStatus == 'Received' ||
      orderStatus == 'Pending Return' ||
      orderStatus == 'Pending Approval' ||
      orderStatus == 'Returned' ||
      orderStatus == 'Partially Returned' ||
      orderStatus == 'Partially Pending Return'
    ) {
      return <span style={statureturn}>Return</span>
    } else if (orderStatus == 'Partially Fulfilled') {
      return <span style={statuShipped}>Partially Fulfilled</span>
    } else if (orderStatus == 'Partially In Process') {
      return <span style={statuShipped}>Partially Back Ordered</span>
    } else if (orderStatus == 'Partially Picked') {
      return <span style={statuShipped}>Partially Picked</span>
    } else if (orderStatus == '' || orderStatus == undefined) {
      return <span>-</span>
    } else {
      return <span>{orderStatus}</span>
    }
  }
  const customTheme = extendTheme({
    components: {
      Accordion: {
        variants: {
          accordion: {
            button: {
              _hover: {
                background: 'transparent',
              },
              svg: {
                width: '28px',
                height: '28px',
                fontSize: '100%',
              },
            },
          },
        },
      },
    },
  })
  const [isExpanded, setIsExpanded] = useState(false)
  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      <Stack display="flex" alignItems="flex-start" gap="var(--chakra-space-3, 12px)" alignSelf="stretch" width="100%">
        <ChakraProvider theme={customTheme}>
          <Accordion allowToggle width={'100%'} background="var(--shading-shading100, #F4F4F4)" variant="accordion">
            <AccordionItem borderBlockStart={0} borderBlockEnd={0} margin="var(--chakra-space-4, 16px)">
              <h2>
                <AccordionButton
                  padding={'0'}
                  paddingStart={0}
                  justifyContent="space-between"
                  alignItems={'flex-start'}
                  onClick={handleToggle}
                >
                  {isExpanded ? (
                    <Box
                      flex={1}
                      fontSize={'sm'}
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      gap={'var(--chakra-space-4, 16px)'}
                      display={'flex'}
                      flexDirection={'column'}
                    >
                      <Text
                        color="var(--semantic-text-primary, #15191C)"
                        textAlign="left"
                        fontWeight={700}
                        fontSize="16px"
                        lineHeight="19.2px"
                        fontFamily={availableFonts.primary}
                      >
                        Shipping Information
                      </Text>
                      <Box style={statusBar} fontFamily={availableFonts.primary}>
                        <OrderStatusMap />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      flex={1}
                      fontSize={'sm'}
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      gap={'var(--chakra-space-4, 16px)'}
                      display={'flex'}
                      flexDirection={'column'}
                    >
                      <Text
                        color="var(--semantic-text-primary, #15191C)"
                        textAlign="left"
                        fontWeight={700}
                        fontSize="16px"
                        lineHeight="19.2px"
                        fontFamily={availableFonts.primary}
                      >
                        Status
                      </Text>
                      <Box style={statusBar} fontFamily={availableFonts.primary}>
                        <OrderStatusMap />
                      </Box>
                    </Box>
                  )}
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel padding={'0'} background="var(--shading-shading100, #F4F4F4)">
                <Grid
                  templateColumns={isMobile ? '1fr' : '1fr 1fr 1fr 1fr'}
                  display="flex"
                  flexDirection="column"
                  alignItems=" flex-start"
                  gap="var(--chakra-space-4, 16px)"
                  align-self="stretch"
                  border-top="1px solid var(--semantic-surface-border, #E7EAED)"
                  border-bottom="1px solid var(--semantic-surface-border, #E7EAED)"
                  background="var(--shading-shading100, #F4F4F4)"
                  width="100%"
                  paddingTop={'var(--chakra-space-4, 16px)'}
                >
                  <Box gap={'var(--sizes-2, 8px)'} display={'flex'} flexDirection={'column'}>
                    <Text
                      color="var(--semantic-text-primary, #15191C)"
                      fontWeight={700}
                      fontSize="16px"
                      lineHeight="19.2px"
                    >
                      Shipped On
                    </Text>
                    {item.deliveryType == 'ShipToAddress' && item?.estimatedShippingDate?.length ? (
                      <Text fontSize={'14px'} fontWeight={500} color={'var(--semantic-text-primary, #15191C)'}>
                        {getDateformat(item?.estimatedShippingDate)}
                      </Text>
                    ) : (
                      <Text>N/A</Text>
                    )}
                  </Box>
                  <Box gap={'var(--sizes-2, 8px)'} display={'flex'} flexDirection={'column'}>
                    <Text
                      color="var(--semantic-text-primary, #15191C)"
                      fontWeight={700}
                      fontSize="16px"
                      lineHeight="19.2px"
                    >
                      Delivery Date
                    </Text>
                    {item.deliveryType == 'ShipToAddress' && item?.estimateDeliveryDate?.lenth ? (
                      <Text fontSize={'14px'} fontWeight={700} color={'var(--semantic-text-primary, #15191C)'}>
                        {item?.estimateDeliveryDate}
                      </Text>
                    ) : (
                      <Text>N/A</Text>
                    )}
                  </Box>
                  <Box gap={'var(--sizes-2, 8px)'} display={'flex'} flexDirection={'column'}>
                    <Text
                      color="var(--semantic-text-primary, #15191C)"
                      fontWeight={700}
                      fontSize="16px"
                      lineHeight="19.2px"
                    >
                      Tracking ID
                    </Text>
                    {item.trackingInfo?.url ? (
                      <Link
                        fontSize={'14px'}
                        fontWeight={400}
                        color={'var(--semantic-text-primary, #15191C)'}
                        textDecoration={'underline'}
                        href={item.trackingInfo.url}
                        isExternal
                      >
                        {' '}
                        <Text>{item.trackingInfo.trackingNo}</Text>
                      </Link>
                    ) : (
                      <Text>N/A</Text>
                    )}
                  </Box>
                  <Box gap={'var(--sizes-2, 8px)'} display={'flex'} flexDirection={'column'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={700}
                      color={'var(--semantic-text-primary, #15191C)'}
                      lineHeight="19.2px"
                    >
                      Shipped To
                    </Text>
                    {item.deliveryAddress?.Address2 ? (
                      <Text fontSize={'14px'} fontWeight={400} color={'var(--semantic-text-primary, #15191C)'}>
                        {' '}
                        {item.deliveryAddress?.Address2}{' '}
                      </Text>
                    ) : (
                      ''
                    )}
                    <Text fontSize={'14px'} fontWeight={400} color={'var(--semantic-text-primary, #15191C)'}>
                      {' '}
                      {item.deliveryAddress?.City} {item.deliveryAddress?.State} {item.deliveryAddress?.PostalCode}{' '}
                    </Text>
                  </Box>
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ChakraProvider>

        <Flex
          paddingBottom="var(--chakra-space-6, 24px)"
          alignItems="flex-start"
          gap="var(--chakra-space-4, 16px)"
          alignSelf="stretch"
          borderBottom={'1px solid var(--semantic-surface-border, #E7EAED)'}
        >
          <Box flexDirection="column" alignItems="flex-start">
            <img width="88px" src={item?.imgUrl} alt="" />
          </Box>

          <Box
            display="flex"
            paddingRight="var(--chakra-space-6, 24px)"
            justifyContent="space-between"
            alignItems="flex-start"
            flex="1 0 0"
            padding="var(--sizes-4, 16px) var(--sizes-4, 16px) var(--sizes-2, 8px) 0px"
            gap={'var(--sizes-3, 12px)'}
          >
            <Box
              display="flex"
              width={isMobile ? 'auto' : '304px'}
              height="auto"
              flexDirection="column"
              alignItems="flex-start"
              gap="var(--chakra-space-0-5, 2px)"
            >
              <Text color="var(--semantic-text-muted,#76797E)" fontSize="12px" fontWeight={400}>
                {item?.brandName}
              </Text>
              <Text fontSize="16px" color="var(--semantic-text-primary, #15191C)">
                {item?.description}
              </Text>
              <Text fontSize="16px" color="var(--semantic-text-primary, #15191C)">
                ${item?.unitPrice}
              </Text>
              <Text fontSize="12px" color="var(--semantic-text-muted,#76797E)">
                Item #: {item?.itemId}
              </Text>
              <Text fontSize="12px" color="var(--semantic-text-muted,#76797E)">
                Color: {item?.selectedColor},{item?.selectedSizes ? 'Size' : ''}: {item?.selectedSizes},
              </Text>
              <Text fontSize="12px" color="var(--semantic-text-muted,#76797E)">
                Promo Applied: ${props?.orderSummary?.discount?.toFixed(2) ?? '0.00'}
              </Text>
            </Box>
          </Box>
        </Flex>
      </Stack>
    </>
  )
}

export default OrderItems
