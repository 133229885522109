import { Box } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoBagOutline } from 'react-icons/io5'

interface CartIconProps {
  cartQuantity?: number
}

export const CartIcon = (props: CartIconProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  return (
    <Box display={'flex'} width={'40px'} alignItems="center" justifyContent="center">
      <IoBagOutline aria-hidden size="26px" color="#111111" />
      <Box
        title={formatMessage({ id: 'header.cart.title' })}
        display="flex"
        transform="translate(12px, -10px)"
        alignItems="center"
        justifyContent="center"
        color="white"
        fontSize="xs"
        fontWeight="bold"
        borderRadius="base"
        bg={props.cartQuantity ? 'red.500' : 'transparent'}
        padding="0 2px"
        minW="15px"
        w="fit-content"
        h="fit-content"
        position="absolute"
      >
        {props.cartQuantity ? props.cartQuantity : ''}
      </Box>
    </Box>
  )
}
